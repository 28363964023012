<template>
  <div>
    <div style="display: inline-block; margin: 0px 0px 0 0;">
      经度：
    </div>
    <div style="display: inline-block; margin: 0px 0px 0 0; width: 200px">
      <el-input v-model="lng" placeholder="经度" readonly></el-input>
    </div>
    <div style="display: inline-block; margin: 0px 0px 0 20px;">
      纬度：
    </div>
    <div style="display: inline-block; margin: 0px 0px 0 0; width: 200px">
      <el-input v-model="lat" placeholder="纬度" readonly></el-input>
    </div>
    <div style="display: inline-block; margin: 0px 0px 0 20px;">
      <el-button type="primary" @click="handleSave">保存</el-button>
    </div>
    <div style="display: inline-block; ; width: 250px">可以拖动蓝色图标进行手动调整</div>

    <div style="margin: 20px 0"></div>
    <div style="display: inline-block; margin: 0px 0px 0 20px;">
      地址：
    </div>
    <div class="el-input" style="width: 80%;">
      <div class="el-input__wrapper">
        <input id="addressInput" class="el-input__inner" v-model="address" placeholder="请输入地址进行搜索" />
      </div>
    </div>

    <div style="margin: 20px 0"></div>
    <!-- 地图绑定的元素 -->
    <div id="container"></div>
  </div>
</template>

<script>

import { saveCoordinate } from "@/api/coordinate";
import { ElMessage } from 'element-plus'

export default {
  name: "SelectCoordinate",
  data() {
    return {
      keyValue: this.$route.query.keyValue,
      // 地图
      mapItem: null,
      autoOptions: {
        // 搜索结果绑定的输入框
        input: "addressInput",
        // 输出结果展示的元素 可自定义
        // output: 'search',
        city: "全国",
      },
      // 地图搜索
      placeSearch: null,
      address: this.$route.query.address ?? "",
      lng: this.$route.query.lng ?? "118.589715", //默认经度
      lat: this.$route.query.lat ?? "24.909555", //默认纬度,
      myMarker :null    //点标记
    };
  },
  mounted() {
    this.initAMap();
  },
  methods: {
    initAMap() {
        // 初始化地图对象
        this.mapItem = new this.$AMap.Map("container", {
          // 是否为3D地图模式
          viewMode: "3D",
          // 缩放级别
          zoom: 9,
          resizeEnable: true,
        });
        // 输入提示组件初始化
        this.auto = new this.$AMap.AutoComplete(this.autoOptions);
        // 监听选择下拉框选项后的事件
        this.auto.on("select", this.autoSelect);
        //搜索组件
        this.placeSearch = new this.$AMap.PlaceSearch({
          // 搜索结果的地图
          map: this.mapItem,
          city: "全国",
        });

        this.myMarker = new this.$AMap.Marker({
            // 设置是否可以拖拽
            draggable: true, 
            cursor: 'move',
            position: [this.lng, this.lat],   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: ""
            
        });
        this.myMarker.on("dragend", this.onMarkerDragEnd);
        
        this.mapItem.add(this.myMarker);
        this.mapItem.setFitView();
    },
    autoSelect(autoTips) {
        this.searchaddress(autoTips?.poi.adcode, autoTips?.poi.name)
    },
    searchaddress(adcode, name) {
        this.placeSearch.setCity(adcode);    
        this.placeSearch.search(name,  (status, result) => {
            // 查询成功时，result即对应匹配的POI信息
            console.log(result)
            var pois = result.poiList.pois;
            var poi = pois[0];
            this.mapItem.clearMap();
            this.myMarker.setPosition(poi.location);
            this.myMarker.setTitle(poi.name)
            this.mapItem.add(this.myMarker);
            this.mapItem.setFitView();
            this.lng = poi.location.lng
            this.lat = poi.location.lat
        });
    },
    // 拖动结束后的事件
    onMarkerDragEnd(e) {
        console.log("拖动结束:", e.lnglat);  // 输出拖动结束时的经纬度
        this.lng = e.lnglat.getLng(); // 更新经度
        this.lat = e.lnglat.getLat(); // 更新纬度
    },
      handleSave() {       
          let coordinate = this.lng + ',' + this.lat;
          saveCoordinate(this.keyValue, coordinate).then((response) => {   
              console.log(response);
                if (response.Code == '1000') {
                    ElMessage({
                        message: response.Message + "，请关闭窗口",
                        grouping: true,
                        type: 'Success',
                        duration: 3 * 1000,
                    })
                } 
        });
    }
  },
  unMounted() {
    // 销毁地图
    this.mapItem?.destroy();
  },
};
</script>

<style>

#container {
  width: 90%;
  height: 700px;
  margin: auto;
}
</style>